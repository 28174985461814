<template>
    <v-card>
        <v-toolbar dark color="blue" class="darken-1">
            <v-toolbar-title>
                {{$t('edit_service')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-form>
                <v-container>
                    <v-row>
                        <v-col
                                cols="12"
                        >
                            <v-text-field
                                    v-model="servicesData.serviceName"
                                     :label="$t('service_name') "
                                          dense
                                            outlined
                                    required
                            ></v-text-field>
                        </v-col>

                        <v-col
                                cols="12"
                        >
                            <v-text-field
                                    v-model="servicesData.serviceDescription"
                                    :label="$t('service_description') "
                                          dense
                                            outlined
                                    required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="rgb(157,25,25)" @click="dialogueClose">{{$t('cancel')}}</v-btn>
            <v-btn dark color="blue darken-1" @click="formSubmit">{{$t('save')}}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import axios from "axios";

    export default {
        name: "AirServicesEditForm",
        data() {
            return {
                servicesData: {},
                message: {
                    status: true,
                    text: 'true',
                }
            }
        },
        props: ['editItemIndex'],
        methods: {
            dialogueClose() {
                this.servicesData = {}
                this.$emit('dialogueClose')
            },
            formSubmit() {
                axios.post('Aviation/UpdatePassengerServicesAsync', this.servicesData)
                    .then(() => {
                        this.message.status = true
                        this.message.text = 'Service is Successfully Updated'
                        this.$emit('formResponse', this.message)
                        this.dialogueClose()
                    })
                    .catch(() => {
                        this.message.status = false
                        this.message.text = 'Error Adding Service, Please Contact Admin'
                        this.$emit('formResponse', this.message)
                        this.dialogueClose()
                    })
            },
            async getEditData() {
                const servicesData = await axios.get('Aviation/GetPassengerServicesByIDAsync/' + this.editItemIndex)
                this.servicesData = servicesData.data
                console.log('data:', this.servicesData)
            },
        },
        mounted() {
            this.getEditData()
        }
    }
</script>

<style scoped>

</style>